import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userCouponList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refCouponTable = ref(null)

  // Table Handlers
  const tableColumns = [{
    key: 'code',
    sortable: false,
    label: 'Code',
  },
  {
    key: 'priceDiscount',
    label: 'Price discount',
    sortable: false,
  },

  {
    key: 'startTime',
    label: 'Start time',

    sortable: false,
  },
  {
    key: 'endTime',
    label: 'End time',
    sortable: false,
  },
  {
    key: 'region',
    label: 'Region',
    sortable: false,
  },
  {
    key: 'createdAt',
    sortable: false,
  },
  {
    key: 'action',
  },
  ]
  const perPage = ref(10)
  const totalCoupons = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const outOfStockFilter = ref(null)
  const activeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCouponTable.value
      ? refCouponTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCoupons.value,
    }
  })

  const refetchData = () => {
    refCouponTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      refetchData()
    },
  )
  const fetchCoupon = (ctx, callback) => {
    store
      .dispatch('sale/fetchCoupons', {
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const coupons = response.body.data
        totalCoupons.value = response.body.total
        callback(coupons)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching products list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      showTable.value = true
           clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchCoupon,
    tableColumns,
    perPage,
    currentPage,
    totalCoupons,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCouponTable,
    refetchData,
    // Extra
  }
}
