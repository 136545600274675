<template>
  <div>
    <coupon-list-add-new
      :is-add-new-coupon-sidebar-active.sync="isAddNewCouponSidebarActive"
      @refetch-data="refreshTable"
    />
    <coupon-list-edit
      :is-edit-coupon-sidebar-active.sync="isEditCouponSidebarActive"
      :coupon-data="couponData"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('category_create')"
                variant="primary"
                @click="isAddNewCouponSidebarActive = true"
              >
                <span class="text-nowrap">Add coupon</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCouponTable"
          class="position-relative"
          :items="fetchCoupon"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-product', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: Start time -->
          <template #cell(startTime)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.startTime) }}
            </span>
          </template>
          <!-- Column: Start time -->
          <template #cell(endTime)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.endTime) }}
            </span>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: Region -->
          <template #cell(region)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                {{ data.item.region.toUpperCase() }}
              </span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('category_update')"
              @click="showSideBarEdit(data.item.id)"
            >
              Edit
            </b-link>
            <b-link
              v-if="$can('tracking_read')"
              @click="showModalTracking(data.item.id)"
            >
              Tracking
            </b-link>
            <b-link
              @click="deleteCoupon(data.item.id)"
            >
              Delete
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import CouponListAddNew from '@/components/couponManager/CouponListAddNew.vue'
import CouponListEdit from '@/components/couponManager/CouponListEdit.vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import userCouponList from '@/views/couponManager/coupon'
import store from '@/store'
import Category from '@/entity/Category'
import { mapGetters } from 'vuex'
import TrackingStatus from '../modal/TrackingStatus.vue'

export default {
  components: {
    BOverlay,
    TrackingStatus,
    CouponListAddNew,
    CouponListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    showModalTracking(id) {
      this.target = `storeConnection:coupon:${id}`
      this.actor = ''
      this.isShowModalTracking = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteCoupon(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this coupon.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('sale/deleteCoupon', { id })
              .then(() => {
                this.refreshTable()
              }).catch(e => {
              })
          }
        })
    },
    showSideBarEdit(id) {
      store.dispatch('sale/fetchCoupon', { id })
        .then(response => {
          this.isEditCouponSidebarActive = true
          this.couponData = {
            id: response.body.id,
            code: response.body.code,
            priceDiscount: response.body.priceDiscount,
            region: {
              label: this.regionOptions.find(e => e.value === response.body.region).label,
              value: response.body.region,
            },
            dateRange: {
              startDate: response.body.startTime,
              endDate: response.body.endTime,
            },
          }
        })
    },
  },

  setup() {
    const target = ref('')
    const actor = ref('')
    const isShowModalTracking = ref(false)
    const isAddNewCouponSidebarActive = ref(false)
    const isEditCouponSidebarActive = ref(false)
    const couponData = {
      code: '',
      priceDiscount: '',
      region: '',
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }
    const {
      timeout,
      showTable,
      fetchCoupon,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponTable,
      refetchData,
    } = userCouponList()
    return {
      timeout,
      showTable,
      target,
      actor,
      isShowModalTracking,
      couponData,
      // Sidebar
      isEditCouponSidebarActive,
      isAddNewCouponSidebarActive,
      fetchCoupon,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponTable,
      refetchData,

      // Filter
      avatarText,

      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
